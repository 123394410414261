<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>IT技术服务</h3>
            <p>核心团队有10年的技术开发服务经验，通过严苛的标准筛选优秀的开发团队，采用公司自主积累BMBP管理模式<br />为客户提供价格更低、质量更好、工期更有保障的开发服务</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//产品分类-->
    <div class="product_it">
        <div class="w">
            <h3 class="t">IT技术服务产品范围</h3>
            <br />
            <ul>
                <li class="show"><span><i class="icon iconfont">&#xe60d;</i></span><h3>网站建设</h3><p>提升企业的品牌与产品价值</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe63b;</i></span><h3>小程序开发</h3><p>企业视觉识别系统</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe605;</i></span><h3>微信开发</h3><p>每一张出品都显向高端品质</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe72a;</i></span><h3>APP开发</h3><p>源自于大自然无穷的创意体现</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe6cb;</i></span><h3>软件开发</h3><p>包装设计价值,让世界看见您的才华</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe6da;</i></span><h3>IT综合服务</h3><p>包装设计价值,让世界看见您的才华</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe70c;</i></span><h3>数据服务</h3><p>包装设计价值,让世界看见您的才华</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe87d;</i></span><h3>UI设计</h3><p>包装设计价值,让世界看见您的才华</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe614;</i></span><h3>云服务</h3><p>包装设计价值,让世界看见您的才华</p><div class="show_bg">&nbsp;</div></li>
            </ul>
        </div>
    </div>
    <div class="product_it-item">
        <div class="w">
            <ul class="show">
                <li><div class="img"><img src="../../assets/wangzhansheji.png" /></div><h3>企业官网</h3></li>
                <li><div class="img"><img src="../../assets/dianshangtuiguan.png" /></div><h3>商城建设</h3></li>
                <li><div class="img"><img src="../../assets/itzonghezhili.png" /></div><h3>电商网站</h3></li>
                <li><div class="img"><img src="../../assets/menhuwanzhan.png" /></div><h3>门户网站</h3></li>
                <li><div class="img"><img src="../../assets/xiangyinshiwangzhan.png" /></div><h3>响应式网站</h3></li>
                <li><div class="img"><img src="../../assets/dingzhiwangzhan.png" /></div><h3>定制开发</h3></li>
                <li><div class="img"><img src="../../assets/ercikaifa.png" /></div><h3>二次开发</h3></li>
                <li><div class="img"><img src="../../assets/mobanjianzhan.png" /></div><h3>模板建站</h3></li>
                <li><div class="img"><img src="../../assets/yinxiaoxinwangzhan.png" /></div><h3>营销型网站</h3></li>
                <li><div class="img"><img src="../../assets/waimaoxinwangzhan.png" /></div><h3>外贸网站</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/xiaochengxu.png" /></div><h3>微信小程序</h3></li>
                <li><div class="img"><img src="../../assets/baiduxiaochengxu.png" /></div><h3>百度小程序</h3></li>
                <li><div class="img"><img src="../../assets/zhifubaoxiaochengxu.png" /></div><h3>支付宝小程序</h3></li>
                <li><div class="img"><img src="../../assets/diancanxiaochengxu.png" /></div><h3>点餐小程序</h3></li>
                <li><div class="img"><img src="../../assets/xiaochengxuyouxi.png" /></div><h3>小程序游戏</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/gongzhonghao.png" /></div><h3>公众号开发</h3></li>
                <li><div class="img"><img src="../../assets/weishangcheng.png" /></div><h3>微商城</h3></li>
                <li><div class="img"><img src="../../assets/h5.png" /></div><h3>H5开发</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/iosyinyong.png" /></div><h3>IOS应用</h3></li>
                <li><div class="img"><img src="../../assets/anzhuoyingyong.png" /></div><h3>安卓应用</h3></li>
                <li><div class="img"><img src="../../assets/shangchengapp.png" /></div><h3>商城APP</h3></li>
                <li><div class="img"><img src="../../assets/zhiboapp.png" /></div><h3>直播APP</h3></li>
                <li><div class="img"><img src="../../assets/zaixianjiaoxue.png" /></div><h3>在线教育</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/qiyeguanli.png" /></div><h3>企业管理软件</h3></li>
                <li><div class="img"><img src="../../assets/hangyeyingyong.png" /></div><h3>行业应用软件</h3></li>
                <li><div class="img"><img src="../../assets/qianrushi.png" /></div><h3>嵌入式软件</h3></li>
                <li><div class="img"><img src="../../assets/saas.png" /></div><h3>SaaS软件</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/api.png" /></div><h3>API接口</h3></li>
                <li><div class="img"><img src="../../assets/jiaobenkaifa.png" /></div><h3>脚本开发</h3></li>
                <li><div class="img"><img src="../../assets/qianduankaifa.png" /></div><h3>前端开发</h3></li>
                <li><div class="img"><img src="../../assets/houduankaifa.png" /></div><h3>后端开发</h3></li>
                <li><div class="img"><img src="../../assets/ceshifuwu.png" /></div><h3>测试服务</h3></li>
                <li><div class="img"><img src="../../assets/yunweifuwu.png" /></div><h3>运维服务</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/shujuchuli.png" /></div><h3>数据处理</h3></li>
                <li><div class="img"><img src="../../assets/shujufenxi.png" /></div><h3>数据分析</h3></li>
                <li><div class="img"><img src="../../assets/keshihua.png" /></div><h3>可视化</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/app.png" /></div><h3>APP界面</h3></li>
                <li><div class="img"><img src="../../assets/wangzhanui.png" /></div><h3>网站UI设计</h3></li>
                <li><div class="img"><img src="../../assets/ruanjianui.png" /></div><h3>软件界面</h3></li>
                <li><div class="img"><img src="../../assets/youxiui.png" /></div><h3>游戏UI</h3></li>
                <li><div class="img"><img src="../../assets/tubiaosheji.png" /></div><h3>图标设计</h3></li>
                <li><div class="img"><img src="../../assets/dianshangyemian.png" /></div><h3>电商页面</h3></li>
                <li><div class="img"><img src="../../assets/iosyinyong.png" /></div><h3>移动页面</h3></li>
                <li><div class="img"><img src="../../assets/chanpinyuanxinsheji.png" /></div><h3>产品原型设计</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../../assets/ecs.png" /></div><h3>弹性云服务器ECS</h3></li>
                <li><div class="img"><img src="../../assets/shujuku.png" /></div><h3>云数据库</h3></li>
                <li><div class="img"><img src="../../assets/yumingfuwu.png" /></div><h3>域名服务</h3></li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </div>
    <!--//开发服务流程-->
    <div class="develop-index">
        <div class="w">
            <h3 class="t">开发服务流程</h3>
            <div class="d">优质服务本该如此，先进项目管理流程，运筹帷幄，决胜千里</div>
            <p><img src="../../assets/process.png" /></p>
        </div>
    </div>
    <!--//与更多优秀企业一起告诉成长-->
    <div class="ads-index">
      <h3>与更多优秀企业一起高速成长</h3>
      <p>通过领先的技术和严谨的管理，赢得客户的好评，并结成战略合作关系</p>
    </div>
    <!--//客户案例-->
    <div class="clients-index"><img src="../../assets/clients.png" /></div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    mounted: function() {
        $(function(){
            $(".product_it ul li").hover(function(){
                $(".product_it ul li.show").removeClass("show");
                $(this).addClass('show');

                $('.product_it-item ul.show').removeClass("show");
                $('.product_it-item ul').eq($(this).index()).addClass("show");
            });
        });
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.product_it{ height: 590px; position: relative; z-index: 9999;}
.product_it h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.product_it ul,.product_it ul li,.product_it ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}

.product_it ul li{ width: 19%; float: left; border: 1px #ffffff solid; text-align: center; height: 200px; padding-top: 40px; cursor: pointer; color: #000000; cursor: pointer;}
.product_it ul li .show_bg{ display: none;}
.product_it ul li.show{ border: 1px #eeeeee solid; border-radius: 5px; background-color: #ffffff; position: relative;}
.product_it ul li.show .show_bg{ height: 25px; background:url("../../assets/show_bg.png") bottom center no-repeat; position: absolute; bottom: -25px; width: 100%; display: block;}
.product_it ul li span i{ font-size: 45px;}
.product_itn ul li:hover{ color: #004172;}
.product_it ul li h3{ font-size: 18px; font-weight: normal;}
.product_it ul li p{ font-size: 14px;}

.product_it-item{ background-color: #edecec; padding: 50px 0px; position: relative; z-index: 888; margin-top: -30px;}
.product_it-item ul,.product_it-item ul li,.product_it-item ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block;}
.product_it-item ul{ margin-top: 50px; display: none;}
.product_it-item ul li{ width: 18%; float: left; margin-right: 20px; margin-bottom: 20px;}
.product_it-item ul li .img{ height: 250px; background-color: #ffffff; border-radius: 5px; overflow: hidden;}
.product_it-item ul li h3{ line-height: 30px; text-align: center; padding-top: 10px;}
.product_it-item ul.show { display: block;}

.aswer{ margin-bottom: 50px;}
.aswer h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.aswer .d{ font-size: 1rem;}
.aswer .form{ font-size: 1rem; float: left; width: 70%; padding-top: 50px;}
.aswer .form strong,.aswer .form span{ display: block; float: left; font-size: 0.9rem; font-weight: normal; margin-bottom: 10px;}
.aswer .form strong{ width: 10%; text-align: right; padding-top: 5px}
.aswer .form span{ width: 40%;;}
.aswer .form span input{ width: 80%; line-height: 30px; border: 1px #cccccc solid; border-radius: 5px; font-size: 1rem;}
.aswer .form span textarea{ border:1px #cccccc solid; width: 103%; border-radius: 5px;}
.aswer .form span button{ background-color: #036eb8; padding: 10px 30px; border: 1px #036eb8 solid; color: #ffffff; border-radius: 5px; font-size: 0.9rem; font-weight: normal; cursor: pointer;}
.aswer .qrcode{ float: right; width: 250px; border-left: 1px #eeeeee solid; text-align: center;}
.aswer .qrcode img{ margin-bottom: 5px; border: 1px #eeeeee solid; padding: 5px;}
.aswer .qrcode span{ display: block; line-height: 30px;}

.ads-index{ background-color: #e8e7e7; padding: 50px 0px; margin: 50px 0px;}
.ads-index h3,.ads-index p{ text-align: center; margin: 0px; padding: 0px;}
.ads-index h3{ font-size: 25px; font-weight: normal;}
.ads-index p{ font-size: 18px;}

.develop-index h3.t{ font-size: 25px; text-align: center; font-weight: normal; margin-top: 50px; margin-bottom: 0px; padding: 0px;}
.develop-index .d{ font-size: 18px; text-align: center; line-height: 30px; padding-bottom: 30px;}

.clients-index{ text-align: center; padding-bottom: 50px;}
</style>
